import Big from 'big.js';
import {ICurrency} from './interface/currency.model.interface';
import {ITaxedMoney} from './interface/money.model.interface';
import {ObjectList} from './interface/object-list.model.interface';
import {Serializable} from '../interface/serializable.interface';
import formatter from 'format-number';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {IVatRate} from "./interface/vat-rate.model.interface";

export class TaxedMoneyList extends ObjectList<TaxedMoney, ITaxedMoney> implements Serializable {
  toHTML(): string {
    return this.data.map(el => el.toHTML())
      .join('<br>');
  }

  toText(): string {
    return this.data.map(el => el.toText())
      .join('\n');
  }

  protected mapToListElement(instance: ITaxedMoney): TaxedMoney {
    return new TaxedMoney(instance);
  }

  protected mapToSelectableItem(instance: TaxedMoney): ISimpleSelectableData {
    return undefined;
  }
}

export class TaxedMoney implements Serializable {
  private _netto: Big;
  private _brutto: Big;
  private _currency: ICurrency;
  private _vatRate: IVatRate;

  constructor(instance: ITaxedMoney) {
    this._netto = TaxedMoney.createMoneyValue(instance.netto);
    this._brutto = TaxedMoney.createMoneyValue(instance.brutto);
    this._currency = instance.currency;
    this._vatRate = instance.vat;
  }

  private static createMoneyValue(value: any) {
    if (typeof value === 'string') {
      value = value.replace(/,/g, '.')
        .replace(/ /g, '');
    }
    return new Big(value);
  }

  get netto(): Big {
    return this._netto;
  }

  get brutto(): Big {
    return this._brutto;
  }

  get vatRate(): IVatRate {
    return this._vatRate;
  }

  get currency(): ICurrency {
    return this._currency;
  }

  toNetto() {
    const numberPart = this.FORMATTER(this._netto.toNumber());
    const currencyPart = this._currency.shortcut;
    return `${numberPart} ${currencyPart}`;
  }

  private readonly FORMATTER = formatter({decimal: ',', integerSeparator: ' ', padRight: 2});

  toBrutto() {
    const numberPart = this.FORMATTER(this._brutto.toNumber());
    const currencyPart = this._currency.shortcut;
    return `${numberPart} ${currencyPart}`;
  }

  toText() {
    const numberPart = this.FORMATTER(this._netto.toNumber());
    const currencyPart = this._currency.shortcut;
    const vatPart = this._vatRate.label;
    return `${numberPart} ${currencyPart} ${vatPart ? '+ ' : ''}${vatPart}`;
  }

  toHTML(): string {
    return this.toText();
  }
}

