<h1 class="primary-color">{{title}}</h1>
<div class="container">
  <form [formGroup]="searchFormGroup">
    <h3 class="primary-color">Sprawa</h3>
    <app-simple-checkbox-value-accessor label="Tylko trwające" formControlName="onlyOnGoing"></app-simple-checkbox-value-accessor>
    <app-search-state formControlName="stateId"></app-search-state>
    <app-simple-multi-string-value-accessor label="Numer teczki" formControlName="briefcaseNumber"></app-simple-multi-string-value-accessor>
    <app-simple-multi-string-value-accessor label="Stary numer teczki" formControlName="oldBriefcaseNumber">
    </app-simple-multi-string-value-accessor>
      <app-search-by-date-from-to label="Data utworzenia w systemie"
                                  formControlName="coreCreatedAt"></app-search-by-date-from-to>
    <app-search-by-date-from-to label="Data rozpoczęcia w dziale"
                                formControlName="createdAt"></app-search-by-date-from-to>
    <app-search-by-date-from-to label="Data zakończenia w dziale" formControlName="endAt"></app-search-by-date-from-to>
    <app-simple-string-value-accessor label="Opis sprawy"
                                      formControlName="legalCaseDescription"></app-simple-string-value-accessor>
    <app-simple-string-value-accessor label="Email"
                                      formControlName="email"></app-simple-string-value-accessor>
    <app-simple-string-value-accessor label="Telefon"
                                      formControlName="phone"></app-simple-string-value-accessor>
    <app-simple-string-value-accessor label="Kod pocztowy"
                                      formControlName="postalCode"></app-simple-string-value-accessor>
    <app-simple-string-value-accessor label="Numer przesyłki"
                                      formControlName="postingNumber"></app-simple-string-value-accessor>
    <h3 class="primary-color">Prowadzący</h3>
    <app-search-vindicator formControlName="vindicatorId"></app-search-vindicator>
    <app-search-field-attorney formControlName="fieldAttorneyId"></app-search-field-attorney>

    <h3 class="primary-color">Zlecający</h3>
    <ng-container *appIfRoleIn="['ADMIN', 'FIELD_ATTORNEY', 'VINDICATOR', 'POST_SECRETARY']">
      <app-search-customer label="Zlecający" formControlName="instructingId"></app-search-customer>
      <app-search-lessor-group formControlName="instructingGroupId"></app-search-lessor-group>
    </ng-container>
    <app-search-supervisor formControlName="supervisorId"></app-search-supervisor>

    <h3 class="primary-color">Wnioskodawca</h3>
    <app-search-customer label="Wnioskodawca" formControlName="applicantId"></app-search-customer>

    <h3 class="primary-color">Dostawca</h3>
    <app-search-customer label="Dostawca" formControlName="providerId"></app-search-customer>

    <h3 class="primary-color">Dłużnik</h3>
    <app-searcher-customer label="Dłużnik" formControlName="debtorCoreId"></app-searcher-customer>
    <app-simple-string-value-accessor label="NIP" formControlName="debtorNIP"></app-simple-string-value-accessor>
    <app-simple-string-value-accessor label="Numer klienta"
                                      formControlName="debtorNumber"></app-simple-string-value-accessor>

    <h3 class="primary-color">Przedmiot/umowa</h3>
    <app-simple-string-value-accessor label="Numer umowy"
                                      formControlName="contractNumber"></app-simple-string-value-accessor>
    <app-simple-multi-string-value-accessor label="Zewnętrzny ID" formControlName="externalContractId">
        </app-simple-multi-string-value-accessor>
    <app-simple-multiple-search-select-value-accessor label="Typ przemiotu"
                                                      [data]="contractItemTypes"
                                                      formControlName="contractItemTypeId">
    </app-simple-multiple-search-select-value-accessor>
    <app-simple-multiple-search-select-value-accessor label="Typ umowy"
                                            [data]="contractTypes"
                                            formControlName="contractTypeId">
    </app-simple-multiple-search-select-value-accessor>
    <app-simple-multi-string-value-accessor label="Przedmiot"
                                      formControlName="contractItem"></app-simple-multi-string-value-accessor>
    <app-search-by-date-from-to label="Data obioru przejściowego"
                                formControlName="transitionalParkingLotAt"></app-search-by-date-from-to>
    <app-search-by-date-from-to label="Data obioru docelowego"
                                formControlName="targetParkingLotAt"></app-search-by-date-from-to>

  <h3 class="primary-color">Saldo</h3>
  <app-number-range label="Saldo początkowe"
                    formControlName="initialBalance"></app-number-range>
  <app-number-range label="Obecne saldo"
                    formControlName="balance"></app-number-range>
  <app-search-by-date-from-to label="Data ostatniej wpłaty"
                              formControlName="lastPaymentAt"></app-search-by-date-from-to>
  <app-number-range label="Kwota ostatniej wplaty"
                    formControlName="lastPaymentAmount"></app-number-range>
  </form>
</div>
