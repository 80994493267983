import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EventBusService} from "../../../core/services/event-bus.service";
import {ImporterService} from "../../../core/services/importer.service";
import {MatDialogRef} from "@angular/material/dialog";
import {IEpu} from "../../model/interface/epu-import.interface";
import {tap} from "rxjs/operators";
import {DictionaryPosition} from "../../model/dictionary.model";
import {Observable} from "rxjs";
import {IVerification} from "../../model/interface/verification-import.interface";

@Component({
  selector: 'app-import-verification',
  templateUrl: './import-verification.component.html',
  styleUrls: ['./import-verification.component.css']
})
export class ImportVerificationComponent implements OnInit {

  importVerificationForm: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _eventBusService: EventBusService,
              private _importerService: ImporterService,
              private _dialogRef: MatDialogRef<ImportVerificationComponent>) { }

  ngOnInit(): void {
    this._createForm();
  }

  importVerification() {
    const payload = this._createImportEpuPayload();
    const request = this._createImportVerificationRequest(payload);
    return this._handleRequest(request);
  }

  private _createForm() {
    this.importVerificationForm = this._formBuilder.group({
      file: [null, Validators.required]
    })
  }

  get file() {
    return this.importVerificationForm.get('file') as FormControl;
  }

  private _createImportVerificationRequest(payload: IVerification) {
    return this._importerService.importVerification(payload)
      .pipe(
        tap(_ => this._eventBusService.display(DictionaryPosition.OK))
      )
  }

  private _createImportEpuPayload(): IVerification {
    return {
      file: this.importVerificationForm.value.file
    } as IVerification
  }

  private _handleRequest(request: Observable<any>) {
    return request.subscribe(_ => this._dialogRef.close(true));
  }

}
