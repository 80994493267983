import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ISimpleSelectableDataList} from '../model/interface/simple-selectable-data.model.interface';
import {ContractItemService} from '../../core/services/contract-item.service';
import {map} from 'rxjs/operators';
import {ContractItemTypeHelper} from '../helper/contract-item-type.helper';
import {ContractService} from "../../core/services/contract.service";
import {ContractTypeHelper} from "../helper/contract-type.helper";

@Component({
  selector: 'app-legal-case-searcher-view',
  templateUrl: './legal-case-searcher-view.component.html',
  styleUrls: ['./legal-case-searcher-view.component.css']
})
export class LegalCaseSearcherViewComponent implements OnInit {
  @Input() title;
  searchFormGroup: FormGroup;
  contractItemTypes: ISimpleSelectableDataList;
  contractTypes: ISimpleSelectableDataList;

  constructor(private formBuilder: FormBuilder,
              private contractService: ContractService,
              private contractItemService: ContractItemService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.fetchContractItemTypes();
    this.fetchContractTypes();
  }

  private createForm() {
    this.searchFormGroup = this.formBuilder.group({
      onlyOnGoing: false,
      briefcaseNumber: null,
      oldBriefcaseNumber: null,
      stateId: null,
      coreCreatedAt: null,
      createdAt: null,
      endAt: null,
      departmentId: null,
      vindicatorId: null,
      fieldAttorneyId: null,
      contractNumber: null,
      instructingId: null,
      instructingGroupId: null,
      providerId: null,
      applicantId: null,
      debtorCoreId: null,
      legalCaseDescription: null,
      debtorNIP: null,
      supervisorId: null,
      contractItem: null,
      contractItemTypeId: null,
      transitionalParkingLotAt: null,
      targetParkingLotAt: null,
      debtorNumber: null,
      postingNumber: null,
      phone: null,
      email: null,
      postalCode: null,
      initialBalance: null,
      balance: null,
      lastPaymentAt: null,
      lastPaymentAmount: null,
      externalContractId: null
    });
  }

  private fetchContractItemTypes() {
    const request = this.createFetchContractItemTypesRequest();
    request.subscribe(
      (itemTypes) => this.contractItemTypes = itemTypes
    );
  }

  private createFetchContractItemTypesRequest() {
    return this.contractItemService.getAllAvailableContractItemTypes()
      .pipe(
        map((itemTypes) => ContractItemTypeHelper.fromListToSelectableList(itemTypes))
      );
  }

  private fetchContractTypes() {
    const request = this.createFetchContractTypesRequest();
    request.subscribe(
        (contractTypes) => this.contractTypes = contractTypes
    );
  }

  private createFetchContractTypesRequest() {
    return this.contractService.getContractTypes()
        .pipe(
            map((itemTypes) => ContractTypeHelper.fromListToSelectableList(itemTypes))
        );
  }
}
