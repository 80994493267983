import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EventBusService} from "../../../core/services/event-bus.service";
import {ImporterService} from "../../../core/services/importer.service";
import {MatDialogRef} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import {DictionaryPosition} from "../../model/dictionary.model";
import {Observable} from "rxjs";
import {IEpu} from "../../model/interface/epu-import.interface";

@Component({
  selector: 'app-import-epu',
  templateUrl: './import-epu.component.html',
  styleUrls: ['./import-epu.component.css']
})
export class ImportEpuComponent implements OnInit {
  importEpuForm: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _eventBusService: EventBusService,
              private _importerService: ImporterService,
              private _dialogRef: MatDialogRef<ImportEpuComponent>) { }

  ngOnInit(): void {
    this._createForm();
  }

  importEpu() {
    const payload = this._createImportEpuPayload();
    const request = this._createImportEpuRequest(payload);
    return this._handleRequest(request);
  }

  private _createForm() {
    this.importEpuForm = this._formBuilder.group({
      file: [null, Validators.required]
    })
  }

  get file() {
    return this.importEpuForm.get('file') as FormControl;
  }

  private _createImportEpuRequest(payload: IEpu) {
    return this._importerService.importEpu(payload)
      .pipe(
        tap(_ => this._eventBusService.display(DictionaryPosition.OK))
      )
  }

  private _createImportEpuPayload(): IEpu {
    return {
      file: this.importEpuForm.value.file
    } as IEpu
  }

  private _handleRequest(request: Observable<any>) {
    return request.subscribe(_ => this._dialogRef.close(true));
  }

}
