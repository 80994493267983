import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmployeeInfoComponent} from './employee-info/employee-info.component';
import {GoBackComponent} from './go-back/go-back.component';
import {FullNamePipe} from './pipe/full-name.pipe';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {getPolishPaginatorIntl} from './polish-paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RequiredFieldFooterComponent} from './required-field-footer/required-field-footer.component';
import {UploadFileComponent} from './legal-case-shared/upload-file/upload-file.component';
import {CompanyinfoComponent} from './company-info/company-info.component';
import {SearchForCompanyComponent} from './search-for-company/search-for-company.component';
import {MoneyInputComponent} from './money-input/money-input.component';
import {PrivatePersonInfoComponent} from './private-person-info/private-person-info.component';
import {MatTabsModule} from '@angular/material/tabs';
import {FileLabelViewComponent} from './file-label-view/file-label-view.component';
import {ClickStopPropagationDirective} from './directives/click-stop-propagation.directive';
import {SupervisorInfoComponent} from './supervisor-info/supervisor-info.component';
import {MatSelectModule} from '@angular/material/select';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {AddCompanyComponent} from './dialog/add-company/add-company.component';
import {AddPrivatePersonComponent} from './dialog/add-private-person/add-private-person.component';
import {SelectSupervisorComponent} from './select-supervisor/select-supervisor.component';
import {AddSupervisorComponent} from './dialog/add-supervisor/add-supervisor.component';
import {SelectVindicatorComponent} from './select-vindicator/select-vindicator.component';
import {SelectFieldAttorneyComponent} from './select-field-attorney/select-field-attorney.component';
import {MatStepperIntl, MatStepperModule} from '@angular/material/stepper';
import {getPolishStepperIntl} from './polish-stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material/core';
import {DATEPICKER_DATE_FORMAT} from './extras/consts';
import {AddContractItemDebtComponent} from './dialog/add-contract-item-debt/add-contract-item-debt.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LegalCaseEventsComponent} from './legal-case-shared/legal-case-events/legal-case-events.component';
import {LegalCaseFilesComponent} from './legal-case-shared/legal-case-files/legal-case-files.component';
import {SimpleConfirmDialogComponent} from './dialog/simple-confirm-dialog/simple-confirm-dialog.component';
import {LegalCaseContractsComponent} from './legal-case-shared/legal-case-contracts/legal-case-contracts.component';
import {
  SingleLegalCaseDpdContractComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/single-legal-case-contract/single-legal-case-dpd-contract.component';
import {AddPaymentComponent} from './dialog/add-payment/add-payment.component';
import {
  NewLegalCaseContractsComponent
} from './legal-case-shared/new-legal-case-contracts/new-legal-case-contracts.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  NewLegalCaseContractPreviewComponent
} from './legal-case-shared/new-legal-case-contracts/new-legal-case-contract-preview/new-legal-case-contract-preview.component';
import {
  NewLegalCaseDeadlineComponent
} from './legal-case-shared/new-legal-case-deadline/new-legal-case-deadline.component';
import {
  NewLegalCaseDescriptionComponent
} from './legal-case-shared/new-legal-case-description/new-legal-case-description.component';
import {
  NewSingleContractItemComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-items/new-single-contract-item/new-single-contract-item.component';
import {LegalCaseVindicatorComponent} from './legal-case-shared/vindication-vindicator/legal-case-vindicator.component';
import {LegalCaseSupervisorComponent} from './legal-case-shared/legal-case-supervisor/legal-case-supervisor.component';
import {LegalCasePaymentsComponent} from './legal-case-shared/legal-case-payments/legal-case-payments.component';
import {MatTableModule} from '@angular/material/table';
import {EditContractComponent} from './dialog/edit-contract/edit-contract.component';
import {EditContractItemComponent} from './dialog/edit-contract/edit-contract-item/edit-contract-item.component';
import {
  AddContractToExistingLegalCaseComponent
} from './dialog/add-contract-to-existing-legal-case/add-contract-to-existing-legal-case.component';
import {EditLegalEntityComponent} from './dialog/edit-legal-entity/edit-legal-entity.component';
import {
  LegalCaseFieldAttorneyComponent
} from './legal-case-shared/legal-case-field-attorney/legal-case-field-attorney.component';
import {
  LegalCasePostParcelsComponent
} from './legal-case-shared/legal-case-post-parcels/legal-case-post-parcels.component';
import {
  SinglePostParcelComponent
} from './legal-case-shared/legal-case-post-parcels/single-post-parcel/single-post-parcel.component';
import {LegalCaseSearcherViewComponent} from './legal-case-searcher/legal-case-searcher-view.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  ReportFilesAttachmentCreatorComponent
} from './report-files-attachment-creator/report-files-attachment-creator.component';
import {
  SimpleNumberValueAccessorComponent
} from './legal-case-searcher/search-number/simple-number-value-accessor.component';
import {SearchStateComponent} from './legal-case-searcher/search-state/search-state.component';
import {
  SearchByDateFromToComponent
} from './legal-case-searcher/search-by-date-from-to/search-by-date-from-to.component';
import {SearchVindicatorComponent} from './legal-case-searcher/search-vindicator/search-vindicator.component';
import {
  SearchFieldAttorneyComponent
} from './legal-case-searcher/search-field-attorney/search-field-attorney.component';
import {
  SimpleStringValueAccessorComponent
} from './simple-string-value-accessor/simple-string-value-accessor.component';
import {SearchCustomerComponent} from './search-customer/search-customer.component';
import {OnlyIntegersDirective} from './directives/only-integers.directive';
import {
  LegalCaseDescriptionComponent
} from './legal-case-shared/legal-case-description/legal-case-description.component';
import {SearchSupervisorComponent} from './legal-case-searcher/search-supervisor/search-supervisor.component';
import {
  LegalCaseSearcherDialogComponent
} from './dialog/legal-case-searcher-dialog/legal-case-searcher-dialog.component';
import {ReadonlyTextViewComponent} from './readonly-text-view/readonly-text-view.component';
import {
  SingleLegalCaseContractItemsComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/single-legal-case-contract/single-legal-case-contract-items/single-legal-case-contract-items.component';
import {
  SingleContractItemDetailsComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/single-legal-case-contract/single-legal-case-contract-items/single-contract-item-details/single-contract-item-details.component';
import {
  LegalCaseCustomerViewComponent
} from './legal-case-shared/legal-case-customer-view/legal-case-customer-view.component';
import {MatSortModule} from '@angular/material/sort';
import {
  NewSingleContractBasicInfoComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-basic-info/new-single-contract-basic-info.component';
import {
  NewSingleContractTypeComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-basic-info/new-single-contract-type/new-single-contract-type.component';
import {
  NewContractItemsComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-items/new-contract-items.component';
import {
  NewSingleContractItemPreviewComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-items/new-single-contract-item-preview/new-single-contract-item-preview.component';
import {
  SingleLegalCaseContractActionsComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/single-legal-case-contract/single-legal-case-contract-actions/single-legal-case-contract-actions.component';
import {
  SingleContractItemDetailsActionsComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/single-legal-case-contract/single-legal-case-contract-items/single-contract-item-details/single-contract-item-details-actions/single-contract-item-details-actions.component';
import {AddContractDebtComponent} from './dialog/add-contract-debt/add-contract-debt.component';
import {
  SimpleSelectValueAccessorComponent
} from './simple-select-value-accessor/simple-select-value-accessor.component';
import {
  SimpleTextareaValueAccessorComponent
} from './simple-textarea-value-accessor/simple-textarea-value-accessor.component';
import {LegalCaseDebtsComponent} from './legal-case-shared/legal-case-debts/legal-case-debts.component';
import {
  SingleLegalCaseDebtComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/single-legal-case-debt.component';
import {
  SingleLegalCaseDebtActionsComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/single-legal-case-debt-actions/single-legal-case-debt-actions.component';
import {
  EditContractItemDebtComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/edit-contract-item-debt/edit-contract-item-debt.component';
import {
  EditContractDebtComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/edit-contract-debt/edit-contract-debt.component';
import {
  SimpleCheckboxValueAccessorComponent
} from './simple-checkbox-value-accessor/simple-checkbox-value-accessor.component';
import {ThrottleButtonDuringAjaxDirective} from './directives/throttle-button-during-ajax.directive';
import {
  DivideDebtComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/divide-debt/divide-debt.component';
import {
  LoadMarketValueOfItemsComponent
} from './legal-case-shared/load-market-value-of-items/load-market-value-of-items.component';
import {SimpleFileSelectComponent} from './simple-file-select/simple-file-select.component';
import {IfPermissionDirective} from './directives/if-permission.directive';
import {IfRoleInDirective} from './directives/if-role-in.directive';
import {CustomerContactComponent} from './legal-case-shared/legal-case-customer-contact/customer-contact.component';
import {
  SimpleDatetimeValueAccessorComponent
} from './simple-datetime-value-accessor/simple-datetime-value-accessor.component';
import {
  LoadLegalCasePaymentsComponent
} from './legal-case-shared/load-legal-case-payments/load-legal-case-payments.component';
import {LegalCaseRemarksComponent} from './legal-case-shared/legal-case-remarks/legal-case-remarks.component';
import {SingleRemarkComponent} from './legal-case-shared/legal-case-remarks/single-remark/single-remark.component';
import {
  SimpleMultipleSelectValueAccessorComponent
} from './simple-multiple-select-value-accessor/simple-multiple-select-value-accessor.component';
import {AddressInfoComponent} from './address-info/address-info.component';
import {NewPrivatePersonComponent} from './dialog/add-private-person/new-private-person/new-private-person.component';
import {NewAddressComponent} from './new-address/new-address.component';
import {NewCompanyComponent} from './dialog/add-company/new-company/new-company.component';
import {InvoiceDebtsComponent} from './dialog/invoice-debts/invoice-debts.component';
import {
  SingleDebtToInvoiceComponent
} from './dialog/invoice-debts/single-debt-to-invoice/single-debt-to-invoice.component';
import {DemandForReturnComponent} from './dialog/demand-for-return/demand-for-return.component';
import {LegalCaseStateComponent} from './legal-case-shared/legal-case-state/legal-case-state.component';
import {
  LoadLegalCasesBNPComponent
} from './legal-case-shared/load-legal-cases/load-legal-cases-bnp/load-legal-cases-b-n-p.component';
import {
  LoadLegalCasesVolkswagenComponent
} from './legal-case-shared/load-legal-cases/load-legal-cases-volkswagen/load-legal-cases-volkswagen.component';
import {
  LoadLegalCasesMilleniumComponent
} from './legal-case-shared/load-legal-cases/load-legal-cases-millenium/load-legal-cases-millenium.component';
import {CustomerEmailComponent} from './customer-email/customer-email.component';
import {AddCustomerEmailComponent} from './add-customer-email/add-customer-email.component';
import {CustomerPhoneComponent} from './customer-phone/customer-phone.component';
import {AddCustomerPhoneComponent} from './add-customer-phone/add-customer-phone.component';
import {
  NewLegalCaseApplicationsComponent
} from './legal-case-shared/new-legal-case-applications/new-legal-case-applications.component';
import {
  NewSingleApplicationFormComponent
} from './legal-case-shared/new-legal-case-applications/new-single-application/new-single-application-form.component';
import {
  NewLegalCaseApplicationPreviewComponent
} from './legal-case-shared/new-legal-case-applications/new-legal-case-application-preview/new-legal-case-application-preview.component';
import {AddCustomerAddressComponent} from './add-customer-address/add-customer-address.component';
import {ContractSchedulesComponent} from './dialog/contract-schedules/contract-schedules.component';
import {
  SingleScheduleInstallmentsComponent
} from './dialog/contract-schedules/single-schedule-installments/single-schedule-installments.component';
import {
  EditSingleInstallmentComponent
} from './dialog/contract-schedules/single-schedule-installments/edit-single-installment/edit-single-installment.component';
import {SimpleDateValueAccessorComponent} from './simple-date-value-accessor/simple-date-value-accessor.component';
import {
  LegalCaseApplicationsComponent
} from './legal-case-shared/legal-case-applications/legal-case-applications.component';
import {
  SingleLegalCaseApplicationComponent
} from './legal-case-shared/legal-case-applications/single-legal-case-application/single-legal-case-application.component';
import {LegalCaseChatsComponent} from './legal-case-shared/legal-case-chats/legal-case-chats.component';
import {LegalCaseDutiesComponent} from './legal-case-shared/legal-case-duties/legal-case-duties.component';
import {
  CreateLegalCaseDutyComponent
} from './legal-case-shared/legal-case-duties/create-legal-case-duty/create-legal-case-duty.component';
import {
  SimpleRichTextValueAccessorComponent
} from './simple-rich-text-value-accessor/simple-rich-text-value-accessor.component';
import {
  SingleLegalCaseDutyComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/single-legal-case-duty.component';
import {
  LegalCaseDutyCommentsComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/legal-case-duty-comments/legal-case-duty-comments.component';
import {
  SingleLegalCaseDutyCommentComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/legal-case-duty-comments/single-legal-case-duty-comment/single-legal-case-duty-comment.component';
import {
  CreateLegalCaseDutyCommentComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/legal-case-duty-comments/create-legal-case-duty-comment/create-legal-case-duty-comment.component';
import {
  ChangeDutyStateComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/legal-case-duty-comments/change-duty-state/change-duty-state.component';
import {
  ChangeLegalCaseDutyDeadlineComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/legal-case-duty-comments/change-legal-case-duty-deadline/change-legal-case-duty-deadline.component';
import {QuillModule} from 'ngx-quill';
import {MatQuillModule} from './mat-quill/mat-quill-module';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {
  ChangeLegalCaseDutyDescriptionComponent
} from './legal-case-shared/legal-case-duties/single-legal-case-duty/legal-case-duty-comments/change-legal-case-duty-description/change-legal-case-duty-description.component';
import {
  LegalCaseCriminalLawyerComponent
} from './legal-case-shared/legal-case-criminal-lawyer/legal-case-criminal-lawyer.component';
import {ResponsiveTableDirective} from './directives/responsive-table.directive';
import {
  PreUploadFileLabelComponent
} from './legal-case-shared/upload-file/pre-upload-file-label/pre-upload-file-label.component';
import {
  LegalCaseCivilLawyerComponent
} from './legal-case-shared/legal-case-civil-lawyer/legal-case-civil-lawyer.component';
import {
  LegalCaseAdministrationLawyerComponent
} from './legal-case-shared/legal-case-administration-lawyer/legal-case-administration-lawyer.component';
import {
  LegalCaseArrangementsComponent
} from './legal-case-shared/legal-case-arrangements/legal-case-arrangements.component';
import {
  SimpleTriStateButtonValueAccessorComponent
} from './simple-tri-state-button-value-accessor/simple-tri-state-button-value-accessor.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {LegalCaseCourtComponent} from './legal-case-shared/legal-case-court/legal-case-court.component';
import {
  SimpleSearchSelectValueAccessorComponent
} from './simple-type-search-select-value-accessor/simple-search-select-value-accessor.component';
import {
  LegalCaseProsecutorOfficeComponent
} from './legal-case-shared/legal-case-prosecutor-office/legal-case-prosecutor-office.component';
import {LegalCaseSignatureComponent} from './legal-case-shared/legal-case-signature/legal-case-signature.component';
import {
  LegalCaseNotificationsComponent
} from './legal-case-shared/legal-case-notifications/legal-case-notifications.component';
import {
  SingleLegalCaseNotificationComponent
} from './legal-case-shared/legal-case-notifications/single-legal-case-notification/single-legal-case-notification.component';
import {
  CreateNotificationComponent
} from './legal-case-shared/legal-case-notifications/create-notification/create-notification.component';
import {
  EditSingleNotificationComponent
} from './legal-case-shared/legal-case-notifications/single-legal-case-notification/edit-single-notification/edit-single-notification.component';
import {
  EditNotificationDateComponent
} from './legal-case-shared/legal-case-notifications/single-legal-case-notification/edit-single-notification/edit-notification-date/edit-notification-date.component';
import {
  EditNotificationDescriptionComponent
} from './legal-case-shared/legal-case-notifications/single-legal-case-notification/edit-single-notification/edit-notification-description/edit-notification-description.component';
import {SingleLegalCaseViewComponent} from './single-legal-case-view/single-legal-case-view.component';
import {
  ChangeLegalCaseDepartmentComponent
} from './legal-case-shared/change-legal-case-department/change-legal-case-department.component';
import {LegalCaseHistoryComponent} from './legal-case-shared/legal-case-history/legal-case-history.component';
import {RouterModule} from '@angular/router';
import {LegalCaseStageComponent} from './legal-case-shared/legal-case-stage/legal-case-stage.component';
import {
  LegalCaseEventsContainerComponent
} from './legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-events-container/legal-case-events-container.component';
import {
  LegalCaseEventContainerPanelComponent
} from './legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-event-container-panel.component';
import {
  EditableLegalCaseFieldComponent
} from './legal-case-shared/editable-legal-case-field/editable-legal-case-field.component';
import {LegalCaseDeadlinesComponent} from './legal-case-shared/legal-case-deadlines/legal-case-deadlines.component';
import {
  SingleLegalCaseDeadlineComponent
} from './legal-case-shared/legal-case-deadlines/single-legal-case-deadline/single-legal-case-deadline.component';
import {
  CreateLegalCaseDeadlineComponent
} from './legal-case-shared/legal-case-deadlines/create-legal-case-deadline/create-legal-case-deadline.component';
import {
  EditLegalCaseDeadlineComponent
} from './legal-case-shared/legal-case-deadlines/edit-legal-case-deadline/edit-legal-case-deadline.component';
import {AttachFileToDebtComponent} from './dialog/attach-file-to-debt/attach-file-to-debt.component';
import {
  SingleLegalCaseDebtAttachmentsComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/single-legal-case-debt-attachments/single-legal-case-debt-attachments.component';
import {
  SingleLegalCaseDebtAttachmentComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/single-legal-case-debt-attachments/single-legal-case-debt-attachment/single-legal-case-debt-attachment.component';
import {SendEmailComponent} from './send-email/send-email.component';
import {SendSmsComponent} from './send-sms/send-sms.component';
import {ActionButtonDirective} from './directives/action-button.directive';
import {CreatePaymentComponent} from './legal-case-shared/legal-case-payments/create-payment/create-payment.component';
import {LegalCaseCollectionComponent} from './legal-case-collection/legal-case-collection.component';
import {
  SingleLegalCaseContentTabsComponent
} from './single-legal-case-content-tab/single-legal-case-content-tabs.component';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {
  SingleLegalCaseCustomerContactComponent
} from './legal-case-shared/legal-case-customer-contact/single-legal-case-customer-conact/single-legal-case-customer-contact.component';
import {FileViewerComponent} from '../features/file-viewer/file-viewer/file-viewer.component';
import {ImageViewerComponent} from '../features/file-viewer/image-viewer/image-viewer.component';
import {PdfViewerComponent} from '../features/file-viewer/pdf-viewer/pdf-viewer.component';
import {DocViewerComponent} from '../features/file-viewer/doc-viewer/doc-viewer.component';
import {FileViewerCarouselComponent} from '../features/file-viewer/file-viewer-carousel/file-viewer-carousel.component';
import {
  LegalCaseCollectionViewPersonalizationComponent
} from './legal-case-collection/legal-case-collection-view-personalization/legal-case-collection-view-personalization.component';
import {
  LegalCaseCollectionActionsComponent
} from './legal-case-collection/legal-case-collection-actions/legal-case-collection-actions.component';
import {
  LegalCaseCollectionContentComponent
} from './legal-case-collection/legal-case-collection-content/legal-case-collection-content.component';
import {LegalCaseSharedWrapperComponent} from './legal-case-shared/legal-case-shared-wrapper.component';
import {
  LegalCasePaymentsContentComponent
} from './legal-case-shared/legal-case-payments/legal-case-payments-content/legal-case-payments-content.component';
import {
  CreateLegalCaseParcelComponent
} from './legal-case-shared/legal-case-post-parcels/create-legal-case-parcel/create-legal-case-parcel.component';
import {
  LegalCaseFilesContentComponent
} from './legal-case-shared/legal-case-files/legal-case-files-content/legal-case-files-content.component';
import {
  LegalCaseChatsContentComponent
} from './legal-case-shared/legal-case-chats/legal-case-chats-content/legal-case-chats-content.component';
import {
  CreateLegalCaseChatComponent
} from './legal-case-shared/legal-case-chats/create-legal-case-chat/create-legal-case-chat.component';
import {
  SingleLegalCaseChatComponent
} from './legal-case-shared/legal-case-chats/legal-case-chats-content/single-legal-case-chat/single-legal-case-chat.component';
import {
  CreateNewChatMessageComponent
} from './legal-case-shared/legal-case-chats/legal-case-chats-content/single-legal-case-chat/create-new-chat-message/create-new-chat-message.component';
import {
  SingleLegalCaseChatMessageComponent
} from './legal-case-shared/legal-case-chats/legal-case-chats-content/single-legal-case-chat/single-legal-case-chat-message/single-legal-case-chat-message.component';
import {
  CreateLegalCaseRemarkComponent
} from './legal-case-shared/legal-case-remarks/create-legal-case-remark/create-legal-case-remark.component';
import {
  LegalCaseHistoryContentComponent
} from './legal-case-shared/legal-case-history/legal-case-history-content/legal-case-history-content.component';
import {
  SingleLegalCaseContentTabsSingleTabComponent
} from './single-legal-case-content-tab/single-legal-case-content-tabs-single-tab/single-legal-case-content-tabs-single-tab.component';
import {
  SingleLegalCaseViewDesktopComponent
} from './single-legal-case-view/single-legal-case-view-desktop/single-legal-case-view-desktop.component';
import {
  SingleLegalCaseViewMobileComponent
} from './single-legal-case-view/single-legal-case-view-mobile/single-legal-case-view-mobile.component';
import {
  SingleLegalCaseViewContainerContentComponent
} from './single-legal-case-view-container/single-legal-case-view-container-content/single-legal-case-view-container-content.component';
import {
  SingleLegalCaseEventActionsComponent
} from './legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-events-container/single-legal-case-event/single-legal-case-event-actions/single-legal-case-event-actions.component';
import {
  EditLegalCaseEventComponent
} from './legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-events-container/single-legal-case-event/single-legal-case-event-actions/edit-legal-case-event/edit-legal-case-event.component';
import {CronEditorModule} from '@tarktech/ngx-cron-editor';
import {AddTemplateComponent} from './dialog/add-template/add-template.component';
import {HtmlViewerComponent} from './html-viewer/html-viewer.component';
import {TemplateListComponent} from './template-list/template-list.component';
import {
  EditTemplateConfirmDialogComponent
} from './dialog/edit-template-confirm-dialog/edit-template-confirm-dialog.component';
import {SimpleCronValueAccessorComponent} from './simple-cron-value-accessor/simple-cron-value-accessor.component';
import {OptionsScrollDirective} from './directives/options-scroll.directive';
import {DecimalPlacesDirective} from './directives/decimal-places.directive';
import {MatChipsModule} from '@angular/material/chips';
import {BlurOnEnterDirective} from './directives/blur-on-enter.directive';
import {
  SimpleMultiStringValueAccessorComponent
} from './simple-multi-string-value-accessor/simple-multi-string-value-accessor.component';
import {
  NewSingleContractDebtsComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-debts/new-single-contract-debts.component';
import {
  NewSingleContractScheduleComponent
} from './legal-case-shared/new-legal-case-contracts/new-contract-schedules/new-contract-schedule/new-single-contract-schedule.component';
import {
  NewInstallmentComponent
} from './legal-case-shared/new-legal-case-contracts/new-contract-schedules/new-contract-schedule/new-installment/new-installment.component';
import {
  NewContractSchedulesComponent
} from './legal-case-shared/new-legal-case-contracts/new-contract-schedules/new-contract-schedules.component';
import {ReusableFormComponent} from './reusable-form/reusable-form.component';
import {
  AddApplicationToExistingLegalCaseComponent
} from './dialog/add-application-to-existing-legal-case/add-application-to-existing-legal-case.component';
import {
  NewSingleApplicationTypeComponent
} from './legal-case-shared/new-legal-case-applications/new-single-application/new-single-application-type/new-single-application-type.component';
import {AddLegalCaseComponent} from './dialog/add-legal-case/add-legal-case.component';
import {NewLegalCaseComponent} from './legal-case-shared/new-legal-case/new-legal-case.component';
import {
  NewLegalCasePreviewComponent
} from './legal-case-shared/new-legal-case/new-legal-case-preview/new-legal-case-preview.component';
import {SimpleSelectDialogComponent} from './dialog/simple-select-dialog/simple-select-dialog.component';
import {SearchLawyerComponent} from './legal-case-searcher/search-lawyer/search-lawyer.component';
import {PriorityComponent} from './priority/priority.component';
import {MatBadgeModule} from '@angular/material/badge';
import {
  SingleEventResponsesComponent
} from './legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-events-container/single-event-responses/single-event-responses.component';
import {SendVmsComponent} from './send-vms/send-vms.component';
import {
  LegalCaseSmartSearcherComponent
} from './legal-case-shared/legal-case-smart-searcher/legal-case-smart-searcher.component';
import {
  CreateCustomerOverviewComponent
} from './legal-case-shared/legal-case-smart-searcher/customer-search/create-customer-overwiev/create-customer-overview.component';
import {
  CustomerSearchComponent
} from './legal-case-shared/legal-case-smart-searcher/customer-search/customer-search.component';
import {PhoneSearchComponent} from './legal-case-shared/legal-case-smart-searcher/phone-search/phone-search.component';
import {
  CreatePhoneOverviewComponent
} from './legal-case-shared/legal-case-smart-searcher/phone-search/create-phone-overview/create-phone-overview.component';
import {EmailSearchComponent} from './legal-case-shared/legal-case-smart-searcher/email-search/email-search.component';
import {
  CreateEmailOverviewComponent
} from './legal-case-shared/legal-case-smart-searcher/email-search/create-email-overview/create-email-overview.component';
import {
  AddressSearchComponent
} from './legal-case-shared/legal-case-smart-searcher/address-search/address-search.component';
import {
  CreateAddressOverviewComponent
} from './legal-case-shared/legal-case-smart-searcher/address-search/create-address-overview/create-address-overview.component';
import {MapComponent} from './map-component/map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ItemSearchComponent} from './legal-case-shared/legal-case-smart-searcher/item-search/item-search.component';
import {
  CreateItemOverviewComponent
} from './legal-case-shared/legal-case-smart-searcher/item-search/create-item-overview/create-item-overview.component';
import {ColumnsSelectComponent} from './dialog/columns-select/columns-select.component';
import {EventTypeColumnFormComponent} from './column-select/event-type-column-form/event-type-column-form.component';
import {ImportLegalCasesComponent} from './dialog/import-legal-cases/import-legal-cases.component';
import {
  ImportLegalCasesSelectInstructingComponent
} from './dialog/import-legal-cases/import-legal-cases-select-instructing/import-legal-cases-select-instructing.component';
import {
  ImportLegalCasesSelectSupervisorComponent
} from './dialog/import-legal-cases/import-legal-cases-select-supervisor/import-legal-cases-select-supervisor.component';
import {
  ImportLegalCasesSelectFileComponent
} from './dialog/import-legal-cases/import-legal-cases-select-file/import-legal-cases-select-file.component';
import {NgxEditorModule} from 'ngx-editor';
import {RtfEditorComponent} from './rtf-editor/rtf-editor/rtf-editor.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {SelectUserRoleComponent} from './select-user-roles/select-user-role.component';
import {SimpleAutocompleteComponent} from './simple-autocomplete/simple-autocomplete.component';
import {IfRoleNotInDirective} from './directives/if-role-not-in.directive';
import {LegalCaseActionsComponent} from './legal-case-shared/legal-case-actions/legal-case-actions.component';
import {
  SingleLegalCaseActionComponent
} from './legal-case-shared/legal-case-actions/single-legal-case-action/single-legal-case-action.component';
import {
  AttachPaymentToActionDebtComponent
} from './dialog/attach-payment-to-action-debt/attach-payment-to-action-debt.component';
import {ImportPaymentsComponent} from './dialog/import-payments/import-payments.component';
import {
  ImportPaymentsSelectFileComponent
} from './dialog/import-payments/import-payments-select-file/import-payments-select-file.component';
import {
  ImportPaymentsInstructingComponent
} from './dialog/import-payments/import-payments-select-instructing/import-payments-instructing.component';
import {SelectSearchDirectiveDirective} from './directives/select-search-directive.directive';
import {
  SimpleMultipleSearchSelectValueAccessorComponent
} from './simple-multiple-search-select-value-accessor/simple-multiple-search-select-value-accessor.component';
import {
  FirstRegistrationDateGuessComponent
} from './legal-case-shared/legal-case-smart-searcher/first-registration-date-guess/first-registration-date-guess.component';
import {MakePhoneCallComponent} from './dialog/make-phone-call/make-phone-call.component';
import {EventsSummaryComponent} from './dialog/events-summary/events-summary.component';
import {
  LegalCaseOfficeContentComponent
} from "./legal-case-shared/legal-case-office/legal-case-office-content/legal-case-office-content.component";
import {LegalCaseOfficeComponent} from "./legal-case-shared/legal-case-office/legal-case-office.component";
import {SearchLessorGroupComponent} from './legal-case-searcher/search-lessor-group/search-lessor-group.component';
import {AddLessorGroupComponent} from './dialog/add-lessor-group/add-lessor-group.component';
import {LessorGroupInfoComponent} from './lessor-group-info/lessor-group-info.component';
import {SoundViewerComponent} from '../features/file-viewer/sound-viewer/sound-viewer.component';
import {ImportGpsComponent} from './dialog/import-gps/import-gps.component';
import {SearchCustomerCoreComponent} from "./search-customer-core/search-customer-core.component";
import {
  LegalCaseConnectionsComponent
} from './legal-case-shared/legal-case-connections/legal-case-connections.component';
import {EpuConfirmDialogComponent} from './dialog/epu-confirm-dialog/epu-confirm-dialog.component';
import {NumberRangeComponent} from './legal-case-searcher/number-range/number-range.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {
  EventStrategyDirective
} from "./legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-event-create/strategy/event-strategy.directive";
import {
  LegalCaseEventCreateComponent
} from "./legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-event-create/legal-case-event-create.component";
import {ConfirmDataComponent} from './confirm-data/confirm-data.component';
import {
  OfficeConfirmDialogComponent
} from './legal-case-shared/legal-case-office/office-confirm-dialog/office-confirm-dialog.component';
import {LegalCaseSummaryComponent} from './dialog/contract-summary-component/legal-case-summary.component';
import {ColumnSelectFormComponent} from './column-select/column-select-form/column-select-form.component';
import {
  DefaultEventCreateStrategyComponent
} from "./legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-event-create/strategy/default-event-create-strategy/default-event-create-strategy.component";
import {
  InspectionEventCreateStrategyComponent
} from "./legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-event-create/strategy/inspection-event-create-strategy/inspection-event-create-strategy.component";
import {
  SingleLegalCaseActionCodeFormComponent
} from "./legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-events-container/single-legal-case-event/single-legal-case-action-code-form/single-legal-case-action-code-form.component";
import {
  DemandForReleaseConfirmDialogComponent
} from "./dialog/demand-for-release-confirm-dialog/demand-for-release-confirm-dialog.component";
import {
  SingleLegalCaseEvent
} from "./legal-case-shared/legal-case-events/legal-case-event-container-panel/legal-case-events-container/single-legal-case-event/single-legal-case-event.component";
import {NumberColumnFormComponent} from "./column-select/number-column-form/number-column-form.component";
import {DefaultColumnFormComponent} from "./column-select/default-column-form/default-column-form.component";
import {
  ItemPropertiesColumnFormComponent
} from "./column-select/item-properties-column-form-component/item-properties-column-form.component";
import {
  NewSingleContractKindComponent
} from './legal-case-shared/new-legal-case-contracts/new-single-contract-basic-info/new-single-contract-kind/new-single-contract-kind.component';
import {
  CurrencyColumnSelectFormComponent
} from './column-select/currency-select-form/currency-column-select-form.component';
import {DepartmentColumnFormComponent} from './column-select/department-column-form/department-column-form.component';
import {ImportValuationComponent} from './dialog/import-valuation/import-valuation.component';
import {EventTypesColumnFormComponent} from './column-select/event-types-column-form/event-types-column-form.component';
import {
  LegalCaseDebtsContentComponent
} from './legal-case-shared/legal-case-debts/legal-case-debts-content/legal-case-debts-content.component';
import {
  LegalCaseContractsContentComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/legal-case-contracts-content.component';
import {
  SingleLegalCaseContractItemsMapComponent
} from './legal-case-shared/legal-case-contracts/legal-case-contracts-content/single-legal-case-contract/single-legal-case-contract-items/single-legal-case-contract-items-map/single-legal-case-contract-items-map.component';
import {ImportEpuComponent} from "./dialog/import-epu/import-epu.component";
import {DebtorLegalCasesComponent} from './legal-case-shared/debtor-legal-cases/debtor-legal-cases.component';
import { DebtInvoiceSourceDialogComponent } from './dialog/debt-invoice-source/debt-invoice-source-dialog.component';
import { DebtInvoiceSourceSelectComponent } from './debt-invoice-source-select/debt-invoice-source-select.component';
import { SimpleRadioValueAccessorComponent } from './simple-radio-value-accessor/simple-radio-value-accessor.component';
import {MatRadioModule} from "@angular/material/radio";
import { ChangeInvoiceSourceComponent } from './legal-case-shared/legal-case-debts/legal-case-debts-content/single-legal-case-debt/change-invoice-source/change-invoice-source.component';
import { ImportVerificationComponent } from './dialog/import-verification/import-verification.component';

@NgModule({
  declarations: [
    DefaultEventCreateStrategyComponent,
    InspectionEventCreateStrategyComponent,
    LegalCaseEventCreateComponent,
    EventStrategyDirective,
    SearchCustomerCoreComponent,
    LegalCaseOfficeComponent,
    LegalCaseOfficeContentComponent,
    EmployeeInfoComponent,
    GoBackComponent,
    FullNamePipe,
    RequiredFieldFooterComponent,
    UploadFileComponent,
    CompanyinfoComponent,
    SearchForCompanyComponent,
    MoneyInputComponent,
    PrivatePersonInfoComponent,
    FileLabelViewComponent,
    ClickStopPropagationDirective,
    SupervisorInfoComponent,
    AddCompanyComponent,
    AddPrivatePersonComponent,
    SelectSupervisorComponent,
    AddSupervisorComponent,
    SelectVindicatorComponent,
    SelectFieldAttorneyComponent,
    AddContractItemDebtComponent,
    SingleLegalCaseEvent,
    LegalCaseEventsComponent,
    LegalCaseFilesComponent,
    SimpleConfirmDialogComponent,
    LegalCaseContractsComponent,
    SingleLegalCaseDpdContractComponent,
    AddPaymentComponent,
    NewLegalCaseContractsComponent,
    NewLegalCaseContractPreviewComponent,
    NewLegalCaseDeadlineComponent,
    NewLegalCaseDescriptionComponent,
    NewSingleContractItemComponent,
    LegalCaseVindicatorComponent,
    LegalCaseSupervisorComponent,
    LegalCasePaymentsComponent,
    LegalCaseFieldAttorneyComponent,
    EditContractComponent,
    EditContractItemComponent,
    AddContractToExistingLegalCaseComponent,
    EditLegalEntityComponent,
    LegalCasePostParcelsComponent,
    SinglePostParcelComponent,
    LegalCaseSearcherViewComponent,
    ReportFilesAttachmentCreatorComponent,
    SimpleNumberValueAccessorComponent,
    SearchStateComponent,
    SearchByDateFromToComponent,
    SearchVindicatorComponent,
    SearchFieldAttorneyComponent,
    SimpleStringValueAccessorComponent,
    SearchCustomerComponent,
    OnlyIntegersDirective,
    LegalCaseDescriptionComponent,
    SearchSupervisorComponent,
    LegalCaseSearcherDialogComponent,
    ReadonlyTextViewComponent,
    SingleLegalCaseContractItemsComponent,
    SingleContractItemDetailsComponent,
    LegalCaseCustomerViewComponent,
    NewSingleContractBasicInfoComponent,
    NewSingleContractTypeComponent,
    NewSingleContractDebtsComponent,
    NewContractItemsComponent,
    NewSingleContractItemPreviewComponent,
    SingleLegalCaseContractActionsComponent,
    SingleContractItemDetailsActionsComponent,
    AddContractDebtComponent,
    SimpleSelectValueAccessorComponent,
    SimpleTextareaValueAccessorComponent,
    LegalCaseDebtsComponent,
    SingleLegalCaseDebtComponent,
    SingleLegalCaseDebtActionsComponent,
    EditContractItemDebtComponent,
    EditContractDebtComponent,
    SimpleCheckboxValueAccessorComponent,
    ThrottleButtonDuringAjaxDirective,
    DivideDebtComponent,
    LoadMarketValueOfItemsComponent,
    SimpleFileSelectComponent,
    IfPermissionDirective,
    IfRoleInDirective,
    CustomerContactComponent,
    SimpleDatetimeValueAccessorComponent,
    LoadLegalCasePaymentsComponent,
    LegalCaseRemarksComponent,
    SingleRemarkComponent,
    SimpleMultipleSearchSelectValueAccessorComponent,
    AddressInfoComponent,
    NewPrivatePersonComponent,
    NewAddressComponent,
    NewCompanyComponent,
    InvoiceDebtsComponent,
    SingleDebtToInvoiceComponent,
    DemandForReturnComponent,
    LegalCaseStateComponent,
    LoadLegalCasesBNPComponent,
    LoadLegalCasesVolkswagenComponent,
    LoadLegalCasesMilleniumComponent,
    CustomerEmailComponent,
    AddCustomerEmailComponent,
    CustomerPhoneComponent,
    AddCustomerPhoneComponent,
    NewLegalCaseApplicationsComponent,
    NewSingleApplicationFormComponent,
    NewLegalCaseApplicationPreviewComponent,
    AddCustomerAddressComponent,
    ContractSchedulesComponent,
    SingleScheduleInstallmentsComponent,
    EditSingleInstallmentComponent,
    SimpleDateValueAccessorComponent,
    LegalCaseApplicationsComponent,
    SingleLegalCaseApplicationComponent,
    LegalCaseChatsComponent,
    SingleLegalCaseChatComponent,
    SingleLegalCaseChatMessageComponent,
    CreateNewChatMessageComponent,
    LegalCaseDutiesComponent,
    CreateLegalCaseDutyComponent,
    SimpleRichTextValueAccessorComponent,
    SingleLegalCaseDutyComponent,
    LegalCaseDutyCommentsComponent,
    SingleLegalCaseDutyCommentComponent,
    CreateLegalCaseDutyCommentComponent,
    ChangeDutyStateComponent,
    ChangeLegalCaseDutyDeadlineComponent,
    ChangeLegalCaseDutyDescriptionComponent,
    LegalCaseCriminalLawyerComponent,
    ResponsiveTableDirective,
    PreUploadFileLabelComponent,
    LegalCaseCivilLawyerComponent,
    LegalCaseAdministrationLawyerComponent,
    LegalCaseArrangementsComponent,
    SimpleTriStateButtonValueAccessorComponent,
    LegalCaseCourtComponent,
    SimpleSearchSelectValueAccessorComponent,
    LegalCaseProsecutorOfficeComponent,
    LegalCaseSignatureComponent,
    LegalCaseNotificationsComponent,
    SingleLegalCaseNotificationComponent,
    CreateNotificationComponent,
    EditSingleNotificationComponent,
    EditNotificationDateComponent,
    EditNotificationDescriptionComponent,
    SingleLegalCaseViewComponent,
    ChangeLegalCaseDepartmentComponent,
    LegalCaseHistoryComponent,
    LegalCaseStageComponent,
    LegalCaseEventsContainerComponent,
    LegalCaseEventContainerPanelComponent,
    EditableLegalCaseFieldComponent,
    LegalCaseDeadlinesComponent,
    SingleLegalCaseDeadlineComponent,
    CreateLegalCaseDeadlineComponent,
    EditLegalCaseDeadlineComponent,
    AttachFileToDebtComponent,
    SingleLegalCaseDebtAttachmentsComponent,
    SingleLegalCaseDebtAttachmentComponent,
    SendEmailComponent,
    SendSmsComponent,
    ActionButtonDirective,
    CreatePaymentComponent,
    LegalCaseCollectionComponent,
    SingleLegalCaseContentTabsComponent,
    SingleLegalCaseCustomerContactComponent,
    FileViewerComponent,
    FileViewerCarouselComponent,
    ImageViewerComponent,
    PdfViewerComponent,
    SoundViewerComponent,
    DocViewerComponent,
    LegalCaseCollectionViewPersonalizationComponent,
    LegalCaseCollectionActionsComponent,
    LegalCaseCollectionContentComponent,
    LegalCaseSharedWrapperComponent,
    LegalCasePaymentsContentComponent,
    CreateLegalCaseParcelComponent,
    LegalCaseFilesContentComponent,
    LegalCaseChatsContentComponent,
    CreateLegalCaseChatComponent,
    CreateLegalCaseRemarkComponent,
    LegalCaseHistoryContentComponent,
    SingleLegalCaseContentTabsSingleTabComponent,
    SingleLegalCaseViewMobileComponent,
    SingleLegalCaseViewDesktopComponent,
    SingleLegalCaseViewContainerContentComponent,
    SingleLegalCaseEventActionsComponent,
    EditLegalCaseEventComponent,
    AddTemplateComponent,
    HtmlViewerComponent,
    TemplateListComponent,
    EditTemplateConfirmDialogComponent,
    SimpleCronValueAccessorComponent,
    OptionsScrollDirective,
    DecimalPlacesDirective,
    SimpleMultiStringValueAccessorComponent,
    BlurOnEnterDirective,
    SimpleMultiStringValueAccessorComponent,
    NewSingleContractScheduleComponent,
    NewInstallmentComponent,
    NewContractSchedulesComponent,
    ReusableFormComponent,
    AddApplicationToExistingLegalCaseComponent,
    NewSingleApplicationTypeComponent,
    AddLegalCaseComponent,
    NewLegalCaseComponent,
    NewLegalCasePreviewComponent,
    SimpleSelectDialogComponent,
    SearchLawyerComponent,
    PriorityComponent,
    SingleEventResponsesComponent,
    SendVmsComponent,
    LegalCaseSmartSearcherComponent,
    CreateCustomerOverviewComponent,
    CustomerSearchComponent,
    PhoneSearchComponent,
    CreatePhoneOverviewComponent,
    EmailSearchComponent,
    CreateEmailOverviewComponent,
    AddressSearchComponent,
    CreateAddressOverviewComponent,
    MapComponent,
    ItemSearchComponent,
    CreateItemOverviewComponent,
    ColumnsSelectComponent,
    DefaultColumnFormComponent,
    NumberColumnFormComponent,
    EventTypeColumnFormComponent,
    ImportLegalCasesComponent,
    ImportPaymentsComponent,
    ImportPaymentsSelectFileComponent,
    ImportPaymentsInstructingComponent,
    ImportLegalCasesSelectInstructingComponent,
    ImportLegalCasesSelectSupervisorComponent,
    ImportLegalCasesSelectFileComponent,
    RtfEditorComponent,
    SelectUserRoleComponent,
    ItemPropertiesColumnFormComponent,
    SimpleAutocompleteComponent,
    IfRoleNotInDirective,
    LegalCaseActionsComponent,
    SingleLegalCaseActionComponent,
    SingleLegalCaseActionCodeFormComponent,
    AttachPaymentToActionDebtComponent,
    SelectSearchDirectiveDirective,
    SimpleMultipleSelectValueAccessorComponent,
    FirstRegistrationDateGuessComponent,
    SimpleMultipleSelectValueAccessorComponent,
    LegalCaseOfficeComponent,
    LegalCaseOfficeContentComponent,
    MakePhoneCallComponent,
    EventsSummaryComponent,
    SearchLessorGroupComponent,
    AddLessorGroupComponent,
    LessorGroupInfoComponent,
    ImportGpsComponent,
    LegalCaseConnectionsComponent,
    NumberRangeComponent,
    EpuConfirmDialogComponent,
    ConfirmDataComponent,
    OfficeConfirmDialogComponent,
    DemandForReleaseConfirmDialogComponent,
    LegalCaseSummaryComponent,
    ColumnSelectFormComponent,
    CurrencyColumnSelectFormComponent,
    DepartmentColumnFormComponent,
    NewSingleContractKindComponent,
    ImportValuationComponent,
    EventTypesColumnFormComponent,
    LegalCaseDebtsContentComponent,
    LegalCaseContractsContentComponent,
    SingleLegalCaseContractItemsMapComponent,
    ImportEpuComponent,
    DebtorLegalCasesComponent,
    DebtInvoiceSourceDialogComponent,
    DebtInvoiceSourceSelectComponent,
    SimpleRadioValueAccessorComponent,
    ChangeInvoiceSourceComponent,
    ImportVerificationComponent
  ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatToolbarModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDividerModule,
        QuillModule.forRoot(),
        MatDatepickerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatSortModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatButtonToggleModule,
        RouterModule,
        MatListModule,
        MatSidenavModule,
        MatRippleModule,
        CronEditorModule,
        MatChipsModule,
        MatBadgeModule,
        LeafletModule,
        NgxEditorModule,
        AngularEditorModule,
        NgxMatSelectSearchModule,
        MatRadioModule
    ],
  exports: [
    QuillModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // components
    EmployeeInfoComponent,
    GoBackComponent,
    RequiredFieldFooterComponent,
    UploadFileComponent,
    CompanyinfoComponent,
    SearchForCompanyComponent,
    PrivatePersonInfoComponent,
    AddCompanyComponent,
    AddContractItemDebtComponent,
    LegalCaseVindicatorComponent,
    LegalCaseSupervisorComponent,
    LegalCasePaymentsComponent,
    LegalCaseFieldAttorneyComponent,
    SimpleDateValueAccessorComponent,
    ChangeLegalCaseDepartmentComponent,
    AddTemplateComponent,
    HtmlViewerComponent,
    AddLegalCaseComponent,

    // pipes
    FullNamePipe,
    ResponsiveTableDirective,
    ClickStopPropagationDirective,
    FileLabelViewComponent,
    SupervisorInfoComponent,
    MoneyInputComponent,
    SelectSupervisorComponent,
    SelectVindicatorComponent,
    SelectFieldAttorneyComponent,
    SingleLegalCaseEvent,
    LegalCaseEventsComponent,
    LegalCaseFilesComponent,
    SimpleConfirmDialogComponent,
    LegalCaseContractsComponent,
    NewLegalCaseContractsComponent,
    NewLegalCaseContractPreviewComponent,
    NewLegalCaseDeadlineComponent,
    NewLegalCaseDescriptionComponent,
    NewLegalCaseDescriptionComponent,
    LegalCasePostParcelsComponent,
    LegalCaseSearcherViewComponent,
    LegalCaseDescriptionComponent,
    SearchByDateFromToComponent,
    SearchByDateFromToComponent,
    SimpleStringValueAccessorComponent,
    SearchCustomerComponent,
    ReadonlyTextViewComponent,
    LegalCaseCustomerViewComponent,
    LegalCaseDebtsComponent,
    IfPermissionDirective,
    IfRoleInDirective,
    IfRoleNotInDirective,
    CustomerContactComponent,
    LegalCaseRemarksComponent,
    LegalCaseStateComponent,
    SimpleSelectValueAccessorComponent,
    SimpleTextareaValueAccessorComponent,
    SimpleMultipleSelectValueAccessorComponent,
    NewLegalCaseApplicationsComponent,
    NewLegalCaseApplicationPreviewComponent,
    LegalCaseApplicationsComponent,
    LegalCaseChatsComponent,
    LegalCaseDutiesComponent,
    MatQuillModule,
    ThrottleButtonDuringAjaxDirective,
    LegalCaseCriminalLawyerComponent,
    LegalCaseCivilLawyerComponent,
    LegalCaseAdministrationLawyerComponent,
    LegalCaseArrangementsComponent,
    LegalCaseCourtComponent,
    LegalCaseProsecutorOfficeComponent,
    LegalCaseSignatureComponent,
    SimpleSearchSelectValueAccessorComponent,
    LegalCaseNotificationsComponent,
    SingleLegalCaseViewComponent,
    LegalCaseHistoryComponent,
    LegalCaseStageComponent,
    EditableLegalCaseFieldComponent,
    LegalCaseDeadlinesComponent,
    SimpleNumberValueAccessorComponent,
    SimpleTriStateButtonValueAccessorComponent,
    SimpleCheckboxValueAccessorComponent,
    SimpleRichTextValueAccessorComponent,
    ActionButtonDirective,
    LegalCaseCollectionComponent,
    SingleLegalCaseContentTabsComponent,
    LegalCaseSharedWrapperComponent,
    SingleLegalCaseViewContainerContentComponent,
    TemplateListComponent,
    SimpleDatetimeValueAccessorComponent,
    SimpleCronValueAccessorComponent,
    SimpleMultiStringValueAccessorComponent,
    PriorityComponent,
    SelectUserRoleComponent,
    CustomerSearchComponent,
    SimpleAutocompleteComponent,
    SimpleMultipleSearchSelectValueAccessorComponent,
    SearchSupervisorComponent,
    SearchStateComponent,
    DebtorLegalCasesComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useValue: getPolishPaginatorIntl()
    },
    {
      provide: MatStepperIntl,
      useValue: getPolishStepperIntl()
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        panelClass: ['rjdm-snackbar']
      }
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pl'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATEPICKER_DATE_FORMAT
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    }
  ]
})
export class SharedModule {
}
