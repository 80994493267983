import {Injectable, OnDestroy} from '@angular/core';
import {Subscription, switchMap} from 'rxjs';
import {Gd360StompService} from './gd360-stomp.service';
import {EventBusService} from './event-bus.service';
import {EventKey} from '../../shared/extras/event-key';
import {filter} from 'rxjs/operators';
import {AuthenticationService} from "./authentication.service";

enum WS_STATE {
  CONNECTED,
  CONNECTING,
  DISCONNECTED
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  private _state: WS_STATE;

  constructor(private authenticationService: AuthenticationService,
              private eventBusService: EventBusService,
              private gd360StompService: Gd360StompService) {
    this._state = WS_STATE.DISCONNECTED;
    this.handleEventBus();
  }

  static unsubscribe(topic: Subscription) {
    if (topic !== undefined && topic !== null) {
      topic.unsubscribe();
      topic = null;
    }
  }

  subscribeForceOpenWindow() {
    return this.subscribe(`/user/topic/force-window`)
  }

  subscribeToLegalCaseState(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/states`);
  }

  subscribeToDeadlines(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/deadlines`);
  }

  subscribeToLegalCaseDebts(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/debts`);
  }

  subscribeToPayments(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/payments`);
  }

  subscribeToFiles(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/files`);
  }

  subscribeToLegalCase(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/core`);
  }

  subscribeToEvents(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/events`);
  }

  subscribeToContract(contractId: number) {
    return this.subscribe(`/topic/contracts/${contractId}`)
  }

  subscribeToDebts(contractId: number) {
    return this.subscribe(`/topic/legal-cases/${contractId}/debts`);
  }

  subscribeToContractItem(itemId: number) {
    return this.subscribe(`/topic/items/${itemId}`);
  }

  subscribeToRemarks(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/remarks`);
  }

  subscribeToParcels(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/post-parcel`);
  }

  subscribeToLegalCaseChats(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/chats`);
  }

  subscribeToLegalCaseDuties(legalCaseId: number) {
    return this.subscribe(`/topic/legal-cases/${legalCaseId}/duties`);
  }

  subscribeToNotifications(legalCaseId: number) {
    return this.subscribe(`/user/topic/legal-cases/${legalCaseId}/notifications`);
  }

  subscribeToChatMessages(chatId: number) {
    return this.subscribe(`/topic/chats/${chatId}`);
  }

  subscribeToDutyComments(dutyId: number) {
    return this.subscribe(`/topic/duties/${dutyId}`);
  }

  subscribeToVerifiedItemReport(verifiedItemId: number) {
    return this.subscribe(`/topic/verified-items/${verifiedItemId}`);
  }

  subscribe(topic: string) {
    return this.gd360StompService.subscribe(topic);
  }

  private disconnect() {
    this.gd360StompService.disconnect();
  }

  private connect() {
    this.gd360StompService.connect();
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  private handleEventBus() {
    this.eventBusService.on(EventKey.WS_CONNECT)
      .pipe(
        filter(_ => this._state == WS_STATE.DISCONNECTED)
      ).subscribe(_ => {
      this.connect();
    });

    this.eventBusService.on(EventKey.WS_DISCONNECT)
      .subscribe(_ => this.disconnect());

    this.eventBusService.on(EventKey.WS_ERROR)
      .subscribe(_ => this._state = WS_STATE.DISCONNECTED);

    this.eventBusService.on(EventKey.WS_CONNECTED)
      .subscribe(_ => this._state = WS_STATE.CONNECTED);

    this.eventBusService.on(EventKey.WS_DISCONNECTED)
      .subscribe(_ => this._state = WS_STATE.DISCONNECTED);

    this.eventBusService.on(EventKey.WS_CONNECTING)
      .subscribe(_ => this._state = WS_STATE.CONNECTING);
  }
}

