import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {INewContract} from '../../shared/model/interface/new-contract.model.interface';
import {IContractTypeList} from '../../shared/model/interface/contract-type.model.interface';
import {WebsocketService} from "./websocket.service";
import {DpdContractObservableMapper} from "../../shared/mapper/dpd-contract-observable-mapper.service";
import {ICreatedContract} from "../../shared/model/interface/created-contract.interface";
import {INewApplication} from "../../shared/model/interface/new-application.model.interface";
import {IContractKindList} from "../../shared/model/interface/IContractKind.interface";

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private URL = environment.backendUrl;

  constructor(private http: HttpClient,
              private websocketService: WebsocketService,
              private contractMapper: DpdContractObservableMapper) {
  }

  updateContractNumber(contractId: number, contractNumber: string) {
    const params = new HttpParams()
      .append('contractNumber', contractNumber);
    return this.http.put<void>(this.URL + `/contracts/${contractId}/update/contract-number`, null, {
      params
    });
  }

  deleteContract(contractId: number) {
    return this.http.delete(this.URL + `/contracts/${contractId}/delete`);
  }

  addContracts(legalCaseId: number, contractArray: INewContract[]) {
    return this.http.post<ICreatedContract[]>(this.URL + `/legal-cases/${legalCaseId}/contracts/create`, contractArray);
  }

  addApplications(legalCaseId: number, applicationsArray: INewApplication[]) {
    return this.http.post<number[]>(this.URL + `/legal-cases/${legalCaseId}/applications/create`, applicationsArray);
  }

  getContractTypes() {
    return this.http.get<IContractTypeList>(this.URL + '/contract/types');
  }

  getContractKinds() {
    return this.http.get<IContractKindList>(this.URL + '/contract/kinds');
  }

  getApplicationTypes() {
    return this.http.get<IContractTypeList>(this.URL + '/application/types');
  }

  updateContractType(contractId: number, contractTypeId: any) {
    const params = new HttpParams()
      .append('contractTypeId', contractTypeId);
    return this.http.put<void>(this.URL + `/contracts/${contractId}/update/contract-type`, null, {
      params
    });
  }

  connectToWebsocket(contractId: number) {
    let request = this.websocketService.subscribeToContract(contractId);
    return this.contractMapper.fromWebsocketMessage(request);
  }

  updateContractKind(contractId: number, contractKindId: any) {
    const params = new HttpParams()
      .append('contractTypeId', contractKindId);
    return this.http.put<void>(this.URL + `/contracts/${contractId}/update/contract-kind`, null, {
      params
    });
  }

  updateContractTerminationDate(contractId: number, terminationDate: any) {
    const params = new HttpParams()
      .append('terminationDate', terminationDate);
    return this.http.put<void>(this.URL + `/contracts/${contractId}/update/contract-termination-date`, null, {
      params
    });
  }

  updateContractConclusionDate(contractId: number, conclusionDate: any) {
    const params = new HttpParams()
      .append('conclusionDate', conclusionDate);
    return this.http.put<void>(this.URL + `/contracts/${contractId}/update/contract-conclusion-date`, null, {
      params
    });
  }

  updateContractBankAccountNumber(contractId: number, bankAccountNumber: any) {
    const params = new HttpParams()
      .append('bankAccountNumber', bankAccountNumber);
    return this.http.put<void>(this.URL + `/contracts/${contractId}/update/bank-account-number`, null, {
      params
    });
  }
}
